import { useSelector } from 'src/hooks/useSelector';

type UseCdnImageHost = (path: string, staticHost?: string) => string | undefined;
type UseCdnHost = () => string;

export const useCdnHost: UseCdnHost = () => {
    const { imageResizingCdnHost } = useSelector(({ config }) => config);

    return imageResizingCdnHost;
};

export const useCdnImageHost: UseCdnImageHost = (path, staticHost) => {
    const cdnHost = useCdnHost();

    return `${staticHost || cdnHost}${path}`;
};

export const useBackgroundWithCdn: UseCdnImageHost = (path, staticHost) => {
    const url = useCdnImageHost(path, staticHost);
    return url ? `url("${url}")` : undefined;
};
