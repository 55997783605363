import { useContext } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import contactChatButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_communication/contact_chat_button_click';
import contactChatElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_communication/contact_chat_element_shown';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { Avatar, Button as MagritteButton, Cell, CellText, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { BubbleSquareOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'src/components/VacancyResponseLink';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';

import styles from './contact-chat.less';

interface ContactChatProps {
    vacancyId: number;
    chatButtonInfo: VacancyChatInfoType;
    employerId: number;
    onClick?: () => void;
    isMagritteExp?: boolean;
    isQuestionExp?: boolean;
}

const TrlKeys = {
    title: 'vacancy.contacts.chat.title',
    description: 'vacancy.contacts.chat.description',
    disclaimer: 'vacancy.contacts.chat.disclaimer',
    writeInChatButton: 'vacancy.contacts.chat.button',
    goToChatButton: 'vacancy.contacts.goToChat.button',
};

const ContactChat: TranslatedComponent<ContactChatProps> = ({
    chatButtonInfo,
    vacancyId,
    employerId,
    isMagritteExp,
    isQuestionExp,
    trls,
    ...props
}) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);
    const { isMobile } = useBreakpoint();

    const isOnlyButtonVisible = isMagritteExp && isMobile && isQuestionExp;

    if (!isApplicant) {
        return null;
    }

    if (isMagritteExp) {
        const info = (
            <Cell
                avatar={
                    <Avatar
                        aria-label={trls[TrlKeys.title]}
                        mode="icon"
                        size={48}
                        style="secondary"
                        icon={<BubbleSquareOutlinedSize24 initial="primary" />}
                    />
                }
            >
                <CellText type="title">{trls[TrlKeys.title]}</CellText>
                <CellText type="subtitle">{trls[TrlKeys.description]}</CellText>
            </Cell>
        );

        if (chatButtonInfo.type === 'alreadyApplied' || (chatButtonInfo.type === 'question' && chatButtonInfo.chatId)) {
            return (
                <ElementShownAnchor
                    fn={contactChatElementShown}
                    vacancyId={vacancyId}
                    withResponse={false}
                    hhtmFrom={hhtmSource}
                >
                    <div>
                        {!isOnlyButtonVisible && (
                            <>
                                {info}
                                <VSpacing default={16} />
                            </>
                        )}
                        <div className={classnames({ [styles.chatButton]: !isOnlyButtonVisible })}>
                            <MagritteButton
                                mode="primary"
                                style="accent"
                                stretched={isOnlyButtonVisible}
                                onClick={() => {
                                    contactChatButtonClick({ withResponse: false, hhtmSource });
                                    openChatik?.({ chatId: chatButtonInfo.chatId, view: 'widget' });
                                    props.onClick?.();
                                }}
                            >
                                {trls[isQuestionExp ? TrlKeys.goToChatButton : TrlKeys.writeInChatButton]}
                            </MagritteButton>
                        </div>
                    </div>
                </ElementShownAnchor>
            );
        }

        return (
            <ElementShownAnchor
                fn={contactChatElementShown}
                vacancyId={vacancyId}
                withResponse={true}
                hhtmFrom={hhtmSource}
            >
                <div>
                    {!isOnlyButtonVisible && (
                        <>
                            {info}
                            <VSpacing default={16} />
                        </>
                    )}
                    <VacancyResponseLink
                        vacancyId={vacancyId}
                        employerId={employerId}
                        enableRelocationWarning
                        openChatikAfterResponse
                        hasQuickResponseDisallowed={chatButtonInfo.type === 'question'}
                        isQuestion={chatButtonInfo.type === 'question'}
                        render={(onClick) => (
                            <div className={classnames({ [styles.chatButton]: !isOnlyButtonVisible })}>
                                <MagritteButton
                                    onClick={(event) => {
                                        contactChatButtonClick({ withResponse: true, hhtmSource });
                                        onClick(event);
                                        props.onClick?.();
                                    }}
                                    mode="primary"
                                    style="accent"
                                    loading={!!responseStatus?.isFetching}
                                    disabled={!!responseStatus?.isFetching}
                                    stretched={isOnlyButtonVisible}
                                    data-qa="vacancy-serp__vacancy_response"
                                >
                                    {trls[TrlKeys.writeInChatButton]}
                                </MagritteButton>
                            </div>
                        )}
                    />
                </div>
            </ElementShownAnchor>
        );
    }

    const info = (
        <div className="vacancy-contacts__chat-info">
            <Text size={TextSize.Small} importance={TextImportance.Secondary}>
                {trls[TrlKeys.disclaimer]}
            </Text>
        </div>
    );

    if (chatButtonInfo.type === 'alreadyApplied') {
        return (
            <ElementShownAnchor
                fn={contactChatElementShown}
                vacancyId={vacancyId}
                withResponse={false}
                hhtmFrom={hhtmSource}
            >
                <Gap top>
                    {info}
                    <Button
                        kind={ButtonKind.Primary}
                        onClick={() => {
                            contactChatButtonClick({ withResponse: false, hhtmSource });
                            openChatik?.({ chatId: chatButtonInfo.chatId, view: 'widget' });
                            props.onClick?.();
                        }}
                    >
                        {trls[TrlKeys.writeInChatButton]}
                    </Button>
                </Gap>
            </ElementShownAnchor>
        );
    }

    return (
        <ElementShownAnchor
            fn={contactChatElementShown}
            vacancyId={vacancyId}
            withResponse={true}
            hhtmFrom={hhtmSource}
        >
            <Gap top>
                {info}
                <VacancyResponseLink
                    vacancyId={vacancyId}
                    employerId={employerId}
                    enableRelocationWarning
                    openChatikAfterResponse
                    render={(onClick) => (
                        <Button
                            onClick={(event) => {
                                contactChatButtonClick({ withResponse: true, hhtmSource });
                                onClick(event);
                                props.onClick?.();
                            }}
                            kind={ButtonKind.Primary}
                            loading={
                                responseStatus?.isFetching && (
                                    <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                                )
                            }
                            disabled={!!responseStatus?.isFetching}
                            data-qa="vacancy-serp__vacancy_response"
                        >
                            {trls[TrlKeys.writeInChatButton]}
                        </Button>
                    )}
                />
            </Gap>
        </ElementShownAnchor>
    );
};

export default translation(ContactChat);
