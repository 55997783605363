import { ResumeIdResponseStatuses } from 'src/hooks/useResumeIdResponseStatuses';

export enum RespondedText {
    OpenChat = 'openChat',
    Chat = 'chat',
}

interface ResponsedProps {
    text: RespondedText;
    chatId?: number;
}

export const getRespondedProps = (
    resumeIdResponseStatuses: ResumeIdResponseStatuses | null,
    isMagritte?: boolean
): ResponsedProps | null => {
    if (resumeIdResponseStatuses === null) {
        return null;
    }

    return {
        text: isMagritte ? RespondedText.Chat : RespondedText.OpenChat,
        chatId: resumeIdResponseStatuses.lastModifiedTopic?.chatId || undefined,
    };
};
