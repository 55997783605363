import React from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import isValidKey from 'bloko/common/isValidKey';

import { isNumber } from 'Utils/Utils';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface OrganizationFormProps {
    /** ID организационной формы (ИП, ООО...) */
    organizationFormId: number | null;
    useSuffix?: boolean;
}

const TrlKeys = {
    'organization.form.0': 'organization.form.0',
    'organization.form.1': 'organization.form.1',
    'organization.form.2': 'organization.form.2',
    'organization.form.3': 'organization.form.3',
    'organization.form.4': 'organization.form.4',
    'organization.form.5': 'organization.form.5',
    'organization.form.6': 'organization.form.6',
    'organization.form.7': 'organization.form.7',
    'organization.form.8': 'organization.form.8',
    'organization.form.9': 'organization.form.9',
    'organization.form.10': 'organization.form.10',
    'organization.form.11': 'organization.form.11',
    'organization.form.12': 'organization.form.12',
    'organization.form.13': 'organization.form.13',
    'organization.form.14': 'organization.form.14',
    'organization.form.15': 'organization.form.15',
    'organization.form.16': 'organization.form.16',
    'organization.form.17': 'organization.form.17',
    'organization.form.18': 'organization.form.18',
    'organization.form.19': 'organization.form.19',
    'organization.form.20': 'organization.form.20',
    'organization.form.21': 'organization.form.21',
    'organization.form.22': 'organization.form.22',
    'organization.form.23': 'organization.form.23',
    'organization.form.24': 'organization.form.24',
    'organization.form.25': 'organization.form.25',
    'organization.form.26': 'organization.form.26',
    'organization.form.27': 'organization.form.27',
    'organization.form.28': 'organization.form.28',
    'organization.form.29': 'organization.form.29',
    'organization.form.30': 'organization.form.30',
    'organization.form.31': 'organization.form.31',
    'organization.form.32': 'organization.form.32',
    'organization.form.33': 'organization.form.33',
    'organization.form.34': 'organization.form.34',
    'organization.form.35': 'organization.form.35',
    'organization.form.36': 'organization.form.36',
    'organization.form.37': 'organization.form.37',
    'organization.form.38': 'organization.form.38',
    'organization.form.39': 'organization.form.39',
    'organization.form.40': 'organization.form.40',
    'organization.form.41': 'organization.form.41',
    'organization.form.42': 'organization.form.42',
    'organization.form.43': 'organization.form.43',
    'organization.form.44': 'organization.form.44',
    'organization.form.45': 'organization.form.45',
    'organization.form.46': 'organization.form.46',
    'organization.form.47': 'organization.form.47',
    'organization.form.48': 'organization.form.48',
    'organization.form.49': 'organization.form.49',
    'organization.form.50': 'organization.form.50',
    'organization.form.51': 'organization.form.51',
    'organization.form.52': 'organization.form.52',
    'organization.form.53': 'organization.form.53',
    'organization.form.54': 'organization.form.54',
    'organization.form.55': 'organization.form.55',
    'organization.form.56': 'organization.form.56',
    'organization.form.57': 'organization.form.57',
    'organization.form.58': 'organization.form.58',
    'organization.form.59': 'organization.form.59',
    'organization.form.60': 'organization.form.60',
};

const OrganizationForm: TranslatedComponent<OrganizationFormProps> = ({
    trls,
    organizationFormId = null,
    useSuffix = true,
}) => {
    if (!isNumber(organizationFormId)) {
        return null;
    }
    const organizationFormKey = `organization.form.${organizationFormId}`;
    if (isValidKey(TrlKeys, organizationFormKey)) {
        const organizationForm = TrlKeys[organizationFormKey];
        return <>{`${trls[organizationForm]}${(useSuffix && NON_BREAKING_SPACE) || ''}`}</>;
    }
    return null;
};

export default translation(OrganizationForm);
