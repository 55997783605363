import { ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

export enum LabelColor {
    Green = 'green',
    LightGreen = 'lightGreen',
    LightGreenRedesign = 'lightGreenRedesign',
    Orange = 'orange',
    LightOrange = 'lightOrange',
    Yellow = 'yellow',
    LightYellow = 'lightYellow',
    Gray = 'gray',
    LightGray = 'lightGray',
    Violet = 'violet',
    LightViolet = 'lightViolet',
    LightRed = 'lightRed',
    LightBlue = 'lightBlue',
}

export enum LabelType {
    CustomContent = 'customContent',
    Popular = 'popular',
    Recommend = 'recommend',
    New = 'new',
    SkillsPercentage = 'skillsPercentage',
    Active = 'active',
    NoMoney = 'noMoney',
    Pause = 'pause',
    LimitReached = 'limitReached',
    NoResume = 'no-resume',
    BeFirst = 'be-first',
    RemoteWorkSchedule = 'remote-work-schedule',
}

const TrlKeys = {
    [LabelType.CustomContent]: '',
    [LabelType.Popular]: 'label.popular',
    [LabelType.Recommend]: 'label.recommend',
    [LabelType.New]: 'label.new',
    [LabelType.SkillsPercentage]: 'label.skillsPercentage',
    [LabelType.Active]: 'label.active',
    [LabelType.Pause]: 'label.pause',
    [LabelType.NoMoney]: 'label.no.money',
    [LabelType.LimitReached]: 'label.limitReached',
    [LabelType.NoResume]: 'label.noResume',
    [LabelType.BeFirst]: 'label.fewResponses',
    [LabelType.RemoteWorkSchedule]: 'label.workSchedule.remote',
};

const defaultLabelColors = {
    [LabelType.CustomContent]: LabelColor.Green,
    [LabelType.Popular]: LabelColor.Green,
    [LabelType.Recommend]: LabelColor.Green,
    [LabelType.New]: LabelColor.LightYellow,
    [LabelType.SkillsPercentage]: LabelColor.LightGreen,
    [LabelType.Active]: LabelColor.Green,
    [LabelType.Pause]: LabelColor.Yellow,
    [LabelType.NoMoney]: LabelColor.Gray,
    [LabelType.LimitReached]: LabelColor.Gray,
    [LabelType.NoResume]: LabelColor.LightYellow,
    [LabelType.BeFirst]: LabelColor.LightGreen,
    [LabelType.RemoteWorkSchedule]: LabelColor.LightViolet,
};

interface LabelProps extends ComponentPropsWithoutRef<'span'> {
    type: LabelType;
    color?: LabelColor;
    withCircle?: boolean;
    Content?: React.ReactNode;
    dataQa?: string;
    size?: 'small' | 'medium';
}

/** Метка с предустановленным текстом и дефолтным цветом
 * Для эксперимента skills_matching_c добавлена возможность выводить пользовательский текст с дополнительным компонентом (иконка)
 */
const Label: TranslatedComponent<LabelProps> = ({
    trls,
    color,
    type,
    withCircle,
    Content,
    dataQa,
    size = 'small',
    ...spanProps
}) => {
    const labelColor = color || defaultLabelColors[type];
    return (
        <span
            className={classnames(styles.label, {
                [styles.labelWithCircle]: !!withCircle,
                [styles.labelGreen]: labelColor === LabelColor.Green,
                [styles.labelLightGreen]: labelColor === LabelColor.LightGreen,
                [styles.labelLightGreenRedesign]: labelColor === LabelColor.LightGreenRedesign,
                [styles.labelOrange]: labelColor === LabelColor.Orange,
                [styles.labelLightOrange]: labelColor === LabelColor.LightOrange,
                [styles.labelYellow]: labelColor === LabelColor.Yellow,
                [styles.labelLightYellow]: labelColor === LabelColor.LightYellow,
                [styles.labelGray]: labelColor === LabelColor.Gray,
                [styles.labelLightGray]: labelColor === LabelColor.LightGray,
                [styles.labelViolet]: labelColor === LabelColor.Violet,
                [styles.labelLightViolet]: labelColor === LabelColor.LightViolet,
                [styles.labelLightRed]: labelColor === LabelColor.LightRed,
                [styles.labelLightBlue]: labelColor === LabelColor.LightBlue,
                [styles.labelSizeMedium]: size === 'medium',
            })}
            data-qa={dataQa}
            {...spanProps}
        >
            {withCircle && (
                <span
                    className={classnames(styles.labelCircle, {
                        [styles.labelCircleLightGreen]: labelColor === LabelColor.LightGreen,
                    })}
                />
            )}
            {Content || trls[TrlKeys[type]]}
        </span>
    );
};

export default translation(Label);
